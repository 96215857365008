export const AppConstants = {
  CISLINK: {
    CRON_LAST_INTERVAL_TIME_IN_HRS: 18,
  },
  TERM_AND_CONDITIONS: '',

  DATE_FORMATS: {
    YYYY_DASH_MM_DASH_DD_WITH_TIME: `yyyy-MM-dd'T'HH:mm:ss'Z'`,
    MMM_SPACE_YEAR: `MMM yyyy`,
    YYYY_DASH_MM_DASH_DD: 'YYYY-MM-DD',
    YYYY_DASH_MM_DASH_DDTHH_COLON_mm_COLON_ss: 'YYYY-MM-DDTHH:mm:ss',
    dddd_SPACE_DD_SPACE_MMM: 'dddd DD MMM',
    DD_SPACE_MMM_SPACE_YEAR: `DD MMM YY`,
  },
  VALIDATIONS: {
    EMAIL_REGEX: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    MOBILE_DIGITS_LENGTH: 20,
    MIN_MOBILE_DIGITS_LENGTH: 8,
    NAME_MAX_LENGTH: 50,
    COMMENTS_MAX_LENGTH: 100,
    ZIP_CODE_MAX_LENGTH: 15,
    CITY_MAX_LENGTH: 50,
    COUNTRY_MAX_LENGTH: 25,
    STATE_MAX_LENGTH: 50,
    FIELD_NAME_MAX_LENGTH: 25,
    EIN_MAX_LENGTH: 20,
    ADDRESS_MAX_LENGTH: 100,
    PASSWORD_REGEX: /^(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/i,
    SEASONS_NAME_LENGTH: 50,
    MIN_MOBILE_NUMBER_LENGTH: 4,
    MIN_RETAILER_NAME_LENGTH: 2,
    MAX_RETAILER_NAME_LENGTH: 70,
  },
  EMAIL_MAX_LENGTH: 352,
  OTP_MAX_LENGTH: 6,
  MAX_PASSWORD_PROGRESS_LIMIT: 75,
  PASSWORD_MAX_LENGTH: 50,
  ADD_FIELDS: {
    SEARCH_NAME_MAX_LENGTH: 25,
  },
  DATE_PERIOD: {
    DATE_FORMAT: 'MMM DD, YYYY',
    NINE: 9,
    ONE: 1,
    SIX: 6,
    THREE_SIXTY_FIVE: 365,
    THREE_ZERO: 30,
    YEARS: 'years',
    MONTHS: 'months',
    DAYS: 'days',
  },
  CLOCK_FORMAT: {
    FORMAT_12: 'FORMAT_12',
    FORMAT_24: 'FORMAT_24',
  },
  DATE_FORMATS_SETTINGS: ['DD-MM-YYYY', 'DD-MM-YY', 'YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MMM-YYYY'],
  getTimeConstants: (t) => ({
    [AppConstants.CLOCK_FORMAT.FORMAT_12]: [
      '12:00 PM',
      '03:00 PM',
      '06:00 PM',
      '09:00 PM',
      '12:00 AM',
      '03:00 AM',
      '06:00 AM',
      '09:00 AM',
    ],
    [AppConstants.CLOCK_FORMAT.FORMAT_24]: [
      t('MIDDAY'),
      '15:00',
      '18:00',
      '21:00',
      t('MIDNIGHT'),
      '03:00',
      '06:00',
      '09:00',
    ],
  }),
  TASK_STATUS: (status, primaryStatus, t) => {
    const mapping = {
      PENDING: { text: t('Pending'), color: '#707374' },
      DELAYED: { text: t('Delayed'), color: '#F74141' },
    };
    return mapping[status] || primaryStatus;
  },
  TASK_DEFAULT_STATUS_COLOR: (status) =>
    ({
      Planned: '#707374',
      'In progress': '#EB8205',
      Complete: '#2FB966',
    }[status]),
  GRAPH_DEFAULT_COLORS: ['#EB8205', '#F9C43D', '#00A0BE'],
  O_AUTH: {
    responseCode: 'code',
    clientId: 'strix-ui',
  },
  LANGUAGE_CODE: {
    russian: 'ru',
    english: 'en',
    spanish: 'es',
    romanian: 'ro',
    bulgarian: 'bg',
    hungarian: 'hu',
    polish: 'pl',
    serbia: 'sr-Latn',
  },
  ADMIN: 'ADMIN',
  ROLLOUT_CACHE_KEY: 'rollout_flags',
  FEATURE_FLAG_CACHE_KEY: 'feature_flags',
  ROLLOUT_EXPIRY_HRS: 24,
  Option_Costs: 'Option Costs',
  ROLES: {
    GROWER: 'GROWER',
    ADMIN: 'ADMIN',
    SUPER_ADMIN: 'SUPER_ADMIN',
    SSR: 'SSR',
    retailerHeadshed: 'RETAILER_HEADSHED',
    rsr: 'RSR',
  },
  REQUEST_STATUS: {
    Registration_Submitted: 'Registration_Submitted',
    Registration_Rejected: 'Registration_Rejected',
    Registration_Approved: 'Registration_Approved',
    Agrobonus_Draft: 'Agrobonus_Draft',
    Agrobonus_Contract_Admin_Submitted: 'Agrobonus_Contract_Admin_Submitted',
    Agrobonus_Contract_Admin_Approved: 'Agrobonus_Contract_Admin_Approved',
    Agrobonus_Contract_Admin_Rejected: 'Agrobonus_Contract_Admin_Rejected',
    Growth_Draft: 'Growth_Draft',
    Growth_GRN_Draft: 'Growth_GRN_Draft',
    Growth_GRN_Admin_Submitted: 'Growth_GRN_Admin_Submitted',
    Growth_GRN_Admin_Approved: 'Growth_GRN_Admin_Approved',
    Growth_GRN_Admin_Rejected: 'Growth_GRN_Admin_Rejected',
    Growth_Bonus_Agreement_Draft: 'Growth_Bonus_Agreement_Draft',
    Growth_Bonus_Agreement_Submitted: 'Growth_Bonus_Agreement_Submitted',
    Growth_Bonus_Agreement_Approved: 'Growth_Bonus_Agreement_Approved',
    Growth_Bonus_Agreement_Reject: 'Growth_Bonus_Agreement_Reject',
    PRICE_Fixation_Draft: 'PRICE_Fixation_Draft',
    Price_Fixation_Admin_Submitted: 'Price_Fixation_Admin_Submitted',
    Price_Fixation_Admin_Addendum_Draft: 'Price_Fixation_Admin_Addendum_Draft',
    Price_Fixation_Grower_Addendum_Submitted: 'Price_Fixation_Grower_Addendum_Submitted',
    Price_Fixation_Grower_Addendum_Approved: 'Price_Fixation_Grower_Addendum_Approved',
    Price_Fixation_Grower_Addendum_Rejected: 'Price_Fixation_Grower_Addendum_Rejected',
    ADDENDUM2_DRAFT: 'ADDENDUM2_DRAFT',
    ADDENDUM2_SUBMITTED: 'ADDENDUM2_SUBMITTED',
    ADDENDUM2_REJECTED: 'ADDENDUM2_REJECTED',
    ADDENDUM2_APPROVED: 'ADDENDUM2_APPROVED',
    PAYOUT_PAYMENT_IN_PROGRESS: 'PAYOUT_PAYMENT_IN_PROGRESS',
    PAYOUT_PAYMENT_COMPLETED: 'PAYOUT_PAYMENT_COMPLETED',
  },
  TIN_DOCUMENTS: 'TIN_DOCUMENTS',
  TIN_EDIT_FIELDS: ['Position', 'DirectorName'],
  MAX_TIN_DOCS_ALLOWED: 5,
  MAX_TRAINING_DOCS_ALLOWED: 10,
  GROWER_DETAILS_UPDATE_PROOF_DOC: 'GROWER_DETAILS_UPDATE_PROOF_DOC',
};
export const InstructionText = {
  Campaign_Selection_Criteria: 'Campaign Selection Criteria',
};
export const BUSINESS_UNIT = [
  {
    value: 'Crop Protection',
    label: 'Crop Protection',
  },
  {
    value: 'Seeds',
    label: 'Seeds',
  },
  {
    value: 'Syngenta Digital',
    label: 'Syngenta Digital',
  },
];
export const FEATURE_FLAGS = {
  SYSTEM_DOWNTIME: 'IsSystemDownTime',
  BANK_DETAILS_AFTER_PAYOUT: 'BankDetailsAfterPayout',
};
